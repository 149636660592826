import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCarousel,
    MDBCarouselInner,
    MDBCarouselItem } from 'mdbreact';
import { MDBTable, MDBTableBody, MDBTableHead, MDBDataTable } from 'mdbreact';

import { api } from '../../api';
import Modaladdquotation from './Modaladdquotation';
import LoadingOverlay from 'react-loading-overlay';
import Modaladdquotationlist from './Modaladdquotationlist';



export default class Managehome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      emp:[],
      news:[],
      banner:[],
    };
   this.getbanner = this.getbanner.bind(this);
   this.delete = this.delete.bind(this);
   
  }

  componentDidMount() {
  this.getbanner();
   
  }

  getbanner() {
    this.setState({ loading: true})
    setTimeout(() => {
       //ภายใน
       api.get('selectquotation.php')
       .then(res => {
         console.log(res);
       //  this.setState({ bankname: res.data, loading: false })
         this.setState({ banner: res.data,loading:false})
       }) 
     }, 0);
  }

  delete = (id) => { //เมื่อมีการคลิกลบ ฟังก์ชั่นนี้จะถูกเรียกใช้งาน แล้วส่ง id ที่ต้องการลบไปที่ไฟล์ delete.php
    api.get('deletequotation.php?id=' + id)
        .then((res) => {
            this.getbanner()
        })
  }


render() {
console.log(this.state.news)

const data = {
  columns: [
    {
      label: 'ลำดับ',
      field: 'list',
      sort: 'list',
      width: 150
    },
    {
        label: 'รายการ',
        field: 'PIC',
        sort: 'PIC',
        width: 200
      },
    {
      label: 'ชื่อสมาชิก',
      field: 'NAME',
      sort: 'NAME',
      width: 200
    },
     {
      label: 'เบอร์โทร',
      field: 'DATES',
      sort: 'DATES',
      width: 200
    },
    {
      label: 'ทะเบียน',
      field: 'DATESTB',
      sort: 'DATESTB',
      width: 200
    },
    {
      label: 'จัดการ',
      field: 'FACULTY',
      sort: 'FACULTY',
      width: 100
    }

  ],

  rows: [...this.state.banner.map((data, i) => (
    {
       list: <>{i+1}</>,
       PIC: <> ใบเสนอราคา</>,
       NAME: <>{data.gm_name}</>,
       DATES: <>{data.gm_tel}</>,
       DATESTB: <>{data.gm_carregistration}</>,
       FACULTY: <> 
      <Modaladdquotationlist quotation_id={data.quotation_id} />
      <MDBBtn type="submit" color="brown" className="text-center" size="sm"> <MDBIcon far icon="save" /> พิมพ์</MDBBtn>
       <MDBBtn size="sm" color="deep-orange" onClick={() => this.delete(data.quotation_id)} >
       <MDBIcon far icon="trash-alt" /> ลบ</MDBBtn>
       </>,
      

      //  random: <h5 searchvalue={data.Curriculumopen_TEAM} >tourist <span className="badge badge-danger">narrow</span></h5>,

    }
   ))] 

};

const persons = <MDBDataTable
// searching ={true}
searchLabel="ค้นหา รายการ"
striped
bordered
small 
entries={20}
// entriesOptions={["10", "20"]}
order={["age", "desc"]}
data={data}
entriesLabel="จำนวนที่แสดง "
infoLabel= {["แสดง", "ถึง", "จากทั้งหมด", "รายการ"]}
paginationLabel={["ย้อนกลับ", "ถัดไป"]}
responsive 
sortRows={['random']}
/>

return (
  <LoadingOverlay
  active={this.state.loading}
  spinner
  text='Loading your data...'
  >
  <MDBContainer fluid>
    <br/>
    <h2 className='text-center mt-2'>ระบบจัดการ รายการซ่อม</h2>
    <h4 className='text-center'>ผู้ใช้งาน : {localStorage.getItem('name_member')}</h4>
  <p className='text-center'><Modaladdquotation getbanner={this.getbanner}/></p> 
  <h2 className='text-left'>รายการใบเสนอราคา</h2>
     {persons}
  </MDBContainer>
  </LoadingOverlay>  
)
}
}