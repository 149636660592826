import axios from 'axios';

let url = 'https://www.bankchiangmaiservice.com/cradlebankbackend/'
// let url = 'http://localhost/bankbackend/'
//let url = 'http://kosamphi.kpo.go.th/kosamphigo/kosamphibackend/'
const api = axios.create({
  baseURL: url
});

const URL = axios.create({

  // baseURL: 'https://e-student.kpru.ac.th/refund/api/'
 // baseURL: 'https://e-student.kpru.ac.th/tess/'
 // baseURL: 'http://localhost:3000/adminconference-sd'
 });

const apilogin = axios.create({
//baseURL: 'https://e-student.kpru.ac.th/refund/api/',
//baseURL: 'hhttps://admission.kpru.ac.th/refund/api/',
//baseURL: 'http://localhost:5000/api/',
  headers: {
    'Authorization': 'Bearer ' + window.localStorage.getItem('auth')
  }
});


export { api, apilogin, URL };
