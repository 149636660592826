import React, { Component } from 'react'
import {
    MDBContainer,
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter
} from 'mdbreact';
import { MDBRow, MDBCol, MDBIcon,MDBDataTable } from "mdbreact";
import { Alert, Form, Input, Button, Select, Icon, message, Tag, Badge, Tooltip , Divider } from 'antd';
import { api } from '../../api';
import Swal from 'sweetalert2'
import logo2 from './../img/logo2.jpg'
import { Container } from '@material-ui/core';

export default class Modaladdquotationlist extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            loading: false,
            namework: '',
            fileupload: '',
            namecategory:'',

            name_workorder: '',
            worklistdata: [],
            getadmin:[],
            carparts_id:'',
            quotation_status:'1',
           
            filedesing_workorder: '',

            banner:[],

            carpartlist:[],
            numcarpart:1,

        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
      //  this.handleChangefile = this.handleChangefile.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.getadmin = this.getadmin.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getcarpartlist = this.getcarpartlist.bind(this);
        this.delete = this.delete.bind(this);
       
    }
    onTextChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    //handleChangefile(event) {    this.setState({fileupload: event.target.files[0]});  }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
        this.getadmin();
        this.getcarpartlist();
    }

    delete = (id) => { //เมื่อมีการคลิกลบ ฟังก์ชั่นนี้จะถูกเรียกใช้งาน แล้วส่ง id ที่ต้องการลบไปที่ไฟล์ delete.php
      api.get('deletecarpartlist.php?id_carpartlist=' + id)
          .then((res) => {
              this.getcarpartlist()
          })
    }

    handleChange(event) {    this.setState({carparts_id: event});  }

    getadmin() {
        this.setState({ loading: true})
        setTimeout(() => {
           //ภายใน
           api.get('selectcarparts.php')
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ getadmin: res.data,loading:false})
           }) 
         }, 0);
      }

      getcarpartlist() {
        this.setState({ loading: true})
        setTimeout(() => {
           //ภายใน
           api.get('selectcarpartlist.php?quotation_id='+ this.props.quotation_id)
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ carpartlist: res.data,loading:false})
           }) 
         }, 0);
      }

    handleSubmit(e) {
        e.preventDefault();
        // alert('A name was submitted: ' + this.state.value);
       // const date = new Date().toISOString().slice(0, 10)
       setTimeout(() => {
    
          const datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
          datafrm.append("carparts_id", this.state.carparts_id);
          datafrm.append("quotation_id", this.props.quotation_id);
          datafrm.append("numcarpart", this.state.numcarpart);
        //  datafrm.append("status_category", 1);
          //datafrm.append("id_workorder", this.state.id_workorder);
        
          api.post("insertcarpartlist.php",datafrm,{
                headers: {
                    'content-type': 'multipart/form-data'
                }
            })
    
       .then(res => {
        if (res.status=== 200) {
            Swal.fire({
                title: 'บันทึกข้อมูลสำเร็จ',
                icon: 'success',
              // showCancelButton: true,
                confirmButtonText: 'ตกลง',
              // cancelButtonText: 'No, keep it'
              }).then((result) => {
               // this.toggle();  
              this.getcarpartlist();
               // this.props.getcategory();
              })
        }else{
          message.error("บันทึกข้อมูลไม่สำเร็จ!")
        }
    
      })
    
    
    }, 2000);
    
    //window.location.href = '/adminfaculty'
    //this.props.updateat();
    //this.getExpert()
     
      }

    render() {
   // console.log(this.state.namework)
 //   console.log(this.state.fileupload)

 const data = {
    columns: [
      {
        label: 'ลำดับ',
        field: 'list',
        sort: 'list',
        width: 150
      },
      {
          label: 'รหัส',
          field: 'PIC',
          sort: 'PIC',
          width: 200
        },
      {
        label: 'รายการ',
        field: 'NAME',
        sort: 'NAME',
        width: 200
      },
       {
        label: 'ราคา/หน่วย',
        field: 'DATES',
        sort: 'DATES',
        width: 200
      },
      {
        label: 'จำนวน',
        field: 'NUM',
        sort: 'NUM',
        width: 200
      },
      {
        label: 'จำนวน',
        field: 'SUMTO',
        sort: 'SUMTO',
        width: 200
      },
     
      {
        label: 'จัดการ',
        field: 'FACULTY',
        sort: 'FACULTY',
        width: 100
      }
  
    ],
  
    rows: [...this.state.carpartlist.map((data, i) => (
      {
         list: <>{i+1}</>,
         PIC: <> {data.carparts_code}</>,
         NAME: <>{data.carparts_name }</>,
         DATES: <>{data.carparts_price}</>,
         NUM: <>{data.carparts_num}</>,
         SUMTO: <>{data.sumto}</>,
         FACULTY: <> 
        
         <MDBBtn size="sm" color="deep-orange" onClick={() => this.delete(data.id_carpartlist)} >
         <MDBIcon far icon="trash-alt" /> ลบ</MDBBtn>
         </>,
        
  
        //  random: <h5 searchvalue={data.Curriculumopen_TEAM} >tourist <span className="badge badge-danger">narrow</span></h5>,
  
      }
     ))] 
  
  };
  
  const persons = <MDBDataTable
  // searching ={true}
  searchLabel="ค้นหา รายการ"
  striped
  bordered
  small 
  entries={20}
  // entriesOptions={["10", "20"]}
  order={["age", "desc"]}
  data={data}
  entriesLabel="จำนวนที่แสดง "
  infoLabel= {["แสดง", "ถึง", "จากทั้งหมด", "รายการ"]}
  paginationLabel={["ย้อนกลับ", "ถัดไป"]}
  responsive 
  sortRows={['random']}
  />


 const { Option } = Select;

 const initialValue = 0;
const sumWithInitial = this.state.carpartlist.reduce(
  (accumulator, currentValue) => accumulator + currentValue.sumto,
  initialValue,
);

//console.log(sumWithInitial);

        return (
            
            <>
                <MDBBtn color="mdb-color" onClick={this.toggle} size='sm'>เพิ่มรายการ</MDBBtn>
                <MDBModal isOpen={this.state.modal} toggle={this.toggle} size='lg'>
                    <MDBModalHeader toggle={this.toggle}>เพิ่มรายการใบเสนอรายการ</MDBModalHeader>
                    <img src={logo2} className="rounded mx-auto d-block w-10 " width={140} height={120}  alt="aligment" /><br/>
                    <Container>
                  <h3 className='text-center'>ใบเสนอราคา</h3>
                  <h4 className='text-center'>หจก.เชียงใหม่แบ้งค์รถยก</h4>
                  <p className='text-left'>209/180 ม.3 ตำบลสัมนาเม็ง อำเภอสันทราย จังหวัดเชียงใหม่ 50210</p>
                  <p className='text-left'>เบอร์โทร 065-0961963</p>
                  <p className='text-left'>เลขผู้เสียภาษี 0503566004851</p>
                    <MDBModalBody>
                        <form onSubmit={this.handleSubmit}>
                            {/* <div className="mb-2 text-left">
                                <label className="font-weight-bold text-left"><MDBIcon icon="bible" /> ชื่ออัลบั้มภาพกิจกรรม</label>
                                <input className="form-control" onChange={this.onTextChange} name="namecategory"
                                    value={this.state.namecategory} placeholder="" required />
                            </div> */}

                            <div className="mb-2 text-left">
                            <label className="font-weight-bold"><MDBIcon icon="bible" /> รายการอะไหล่</label>
                            <Select
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder="เลือกอะไหล่"
                                        optionFilterProp="children"
                                        onChange={this.handleChange}
                                        onFocus={this.onFocus}
                                        onBlur={this.onBlur}
                                        value={this.state.id_user}
                                        // onSearch={onSearch}
                                        // filterOption={(input, option) =>
                                        //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        // }
                                    >
                                        {this.state.getadmin.map((getadmin,index )  => 
                                                            <Option value={getadmin.carparts_id}>{getadmin.carparts_name}  </Option>
                                                            )}
                                        {/* <Option value="jack">Jack</Option>
                                        <Option value="lucy">Lucy</Option>
                                        <Option value="tom">Tom</Option> */}

                                    </Select>
                                    </div>

                                     <div className="mb-2 text-left">
                                <label className="font-weight-bold text-left"><MDBIcon icon="bible" /> จำนวนชิ้น</label>
                                <input className="form-control" onChange={this.onTextChange} name="numcarpart"
                                    value={this.state.numcarpart} placeholder="" required />
                            </div>
                           
                            {/* <p className="purple-text text-center">ตรวจสอบข้อมูลว่าถูกต้องครบถ้วน</p> */}
                            <div className="mt-3 text-center" >
                                <MDBBtn type="submit" color="deep-purple" className="text-center"> <MDBIcon far icon="save" /> เพิ่มรายการ</MDBBtn>
                                {/* <MDBBtn color="secondary" onClick={this.toggle}>ปิด</MDBBtn> */}
                            </div>

                        </form>

                        {persons}
                        <h1 className='text-right'>รวมสุทธิ {sumWithInitial} บาท</h1>
                    </MDBModalBody>
                    </Container>
                    <MDBModalFooter>
                        {/* <MDBBtn color="secondary" onClick={this.toggle}>ปิด</MDBBtn> */}
                        {/* <MDBBtn color="primary">Save changes</MDBBtn> */}
                    </MDBModalFooter>
                </MDBModal>
            </>
        )
    }
}
