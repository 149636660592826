import React, { Component } from 'react'
import {
    MDBContainer,
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter
} from 'mdbreact';
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import { Alert, Form, Input, Button, Select, Icon, message, Tag, Badge, Tooltip , Divider } from 'antd';
import { api } from '../../api';
import Swal from 'sweetalert2'

export default class Modaladdgmmember extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            loading: false,
            namework: '',
            fileupload: '',

            gm_name:'',
            gm_tel:'',
            gm_carregistration:'',

            name_workorder: '',
            worklistdata: [],
           
            filedesing_workorder: '',

        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
      //  this.handleChangefile = this.handleChangefile.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
       
    }
    onTextChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    //handleChangefile(event) {    this.setState({fileupload: event.target.files[0]});  }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        // alert('A name was submitted: ' + this.state.value);
        const date = new Date().toISOString().slice(0, 10)
       setTimeout(() => {
    
          const datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
          datafrm.append("gm_name", this.state.gm_name);
          datafrm.append("gm_tel", this.state.gm_tel);
          datafrm.append("gm_carregistration", this.state.gm_carregistration);
          //datafrm.append("id_workorder", this.state.id_workorder);
        
          api.post("insertgmmember.php",datafrm,{
                headers: {
                    'content-type': 'multipart/form-data'
                }
            })
    
       .then(res => {
        if (res.status=== 200) {
            Swal.fire({
                title: 'บันทึกข้อมูลสำเร็จ',
                icon: 'success',
              // showCancelButton: true,
                confirmButtonText: 'ตกลง',
              // cancelButtonText: 'No, keep it'
              }).then((result) => {
                this.toggle();  
            //  this.getworklist();
                this.props.getcategory();
              })
        }else{
          message.error("บันทึกข้อมูลไม่สำเร็จ!")
        }
    
      })
    
    
    }, 2000);
    
    //window.location.href = '/adminfaculty'
    //this.props.updateat();
    //this.getExpert()
     
      }

    render() {
   // console.log(this.state.namework)
 //   console.log(this.state.fileupload)
        return (
            <MDBContainer>
                <MDBBtn color="pink" onClick={this.toggle}>เพิ่มสมาชิก</MDBBtn>
                <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
                    <MDBModalHeader toggle={this.toggle}>เพิ่มสมาชิก</MDBModalHeader>
                    <MDBModalBody>
                        <form onSubmit={this.handleSubmit}>
                            <div className="mb-2 text-left">
                                <label className="font-weight-bold text-left"><MDBIcon icon="bible" /> ชื่อสมาชิก</label>
                                <input className="form-control" onChange={this.onTextChange} name="gm_name"
                                    value={this.state.gm_name} placeholder="ชื่อสมาชิก" required />
                            </div>
                            <div className="mb-2 text-left">
                                <label className="font-weight-bold text-left"><MDBIcon icon="bible" /> เบอร์โทร</label>
                                <input className="form-control" onChange={this.onTextChange} name="gm_tel"
                                    value={this.state.gm_tel} placeholder="เบอร์โทร" required />
                            </div>
                            <div className="mb-2 text-left">
                                <label className="font-weight-bold text-left"><MDBIcon icon="bible" /> ทะเบียนรถ</label>
                                <input className="form-control" onChange={this.onTextChange} name="gm_carregistration"
                                    value={this.state.gm_carregistration} placeholder="ทะเบียนรถ" required />
                            </div>
                           
                            {/* <p className="purple-text text-center">ตรวจสอบข้อมูลว่าถูกต้องครบถ้วน</p> */}
                            <div className="mt-3 text-center" >
                                <MDBBtn type="submit" color="deep-purple" className="text-center"> <MDBIcon far icon="save" /> เพิ่ม</MDBBtn>
                                <MDBBtn color="secondary" onClick={this.toggle}>ปิด</MDBBtn>
                            </div>

                        </form>
                    </MDBModalBody>
                    <MDBModalFooter>
                        {/* <MDBBtn color="secondary" onClick={this.toggle}>ปิด</MDBBtn> */}
                        {/* <MDBBtn color="primary">Save changes</MDBBtn> */}
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        )
    }
}
