import React, { Component } from 'react'
import {
    MDBContainer,
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter
} from 'mdbreact';
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import { Alert, Form, Input, Button, Select, Icon, message, Tag, Badge, Tooltip , Divider } from 'antd';
import { api } from '../../api';
import Swal from 'sweetalert2'

export default class Modaladdquotation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            loading: false,
            namework: '',
            fileupload: '',
            namecategory:'',

            name_workorder: '',
            worklistdata: [],
            getadmin:[],
            gm_ID:'',
            quotation_status:'1',
           
            filedesing_workorder: '',

        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
      //  this.handleChangefile = this.handleChangefile.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.getadmin = this.getadmin.bind(this);
        this.handleChange = this.handleChange.bind(this);
       
    }
    onTextChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    //handleChangefile(event) {    this.setState({fileupload: event.target.files[0]});  }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
        this.getadmin();
    }

    handleChange(event) {    this.setState({gm_ID: event});  }

    getadmin() {
        this.setState({ loading: true})
        setTimeout(() => {
           //ภายใน
           api.get('selectgmmember.php')
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ getadmin: res.data,loading:false})
           }) 
         }, 0);
      }

    handleSubmit(e) {
        e.preventDefault();
        // alert('A name was submitted: ' + this.state.value);
       // const date = new Date().toISOString().slice(0, 10)
       setTimeout(() => {
    
          const datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
          datafrm.append("gm_ID", this.state.gm_ID);
          datafrm.append("quotation_status", this.state.quotation_status);
        //  datafrm.append("status_category", 1);
          //datafrm.append("id_workorder", this.state.id_workorder);
        
          api.post("insertquotation.php",datafrm,{
                headers: {
                    'content-type': 'multipart/form-data'
                }
            })
    
       .then(res => {
        if (res.status=== 200) {
            Swal.fire({
                title: 'บันทึกข้อมูลสำเร็จ',
                icon: 'success',
              // showCancelButton: true,
                confirmButtonText: 'ตกลง',
              // cancelButtonText: 'No, keep it'
              }).then((result) => {
                this.toggle();  
            //  this.getworklist();
                this.props.getbanner();
              })
        }else{
          message.error("บันทึกข้อมูลไม่สำเร็จ!")
        }
    
      })
    
    
    }, 2000);
    
    //window.location.href = '/adminfaculty'
    //this.props.updateat();
    //this.getExpert()
     
      }

    render() {
   // console.log(this.state.namework)
 //   console.log(this.state.fileupload)
 const { Option } = Select;
        return (
            <MDBContainer>
                <MDBBtn color="pink" onClick={this.toggle}>เพิ่มใบเสนอราคา</MDBBtn>
                <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
                    <MDBModalHeader toggle={this.toggle}>เพิ่มใบเสนอราคา</MDBModalHeader>
                    <MDBModalBody>
                        <form onSubmit={this.handleSubmit}>
                            {/* <div className="mb-2 text-left">
                                <label className="font-weight-bold text-left"><MDBIcon icon="bible" /> ชื่ออัลบั้มภาพกิจกรรม</label>
                                <input className="form-control" onChange={this.onTextChange} name="namecategory"
                                    value={this.state.namecategory} placeholder="" required />
                            </div> */}

                            <div className="mb-2 text-left">
                            <label className="font-weight-bold"><MDBIcon icon="bible" /> ชื่อสมาชิก</label>
                            <Select
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder="เลือกสมาชิก"
                                        optionFilterProp="children"
                                        onChange={this.handleChange}
                                        onFocus={this.onFocus}
                                        onBlur={this.onBlur}
                                        value={this.state.id_user}
                                        // onSearch={onSearch}
                                        // filterOption={(input, option) =>
                                        //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        // }
                                    >
                                        {this.state.getadmin.map((getadmin,index )  => 
                                                            <Option value={getadmin.gm_ID}>{getadmin.gm_name}  </Option>
                                                            )}
                                        {/* <Option value="jack">Jack</Option>
                                        <Option value="lucy">Lucy</Option>
                                        <Option value="tom">Tom</Option> */}

                                    </Select>
                                    </div>
                           
                            {/* <p className="purple-text text-center">ตรวจสอบข้อมูลว่าถูกต้องครบถ้วน</p> */}
                            <div className="mt-3 text-center" >
                                <MDBBtn type="submit" color="deep-purple" className="text-center"> <MDBIcon far icon="save" /> เพิ่ม</MDBBtn>
                                <MDBBtn color="secondary" onClick={this.toggle}>ปิด</MDBBtn>
                            </div>

                        </form>
                    </MDBModalBody>
                    <MDBModalFooter>
                        {/* <MDBBtn color="secondary" onClick={this.toggle}>ปิด</MDBBtn> */}
                        {/* <MDBBtn color="primary">Save changes</MDBBtn> */}
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        )
    }
}
